/**
* Generated automatically at built-time (2024-08-30T15:37:07.896Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "buitenkeukens-modules",templateKey: "sites/107-26600989-f079-4508-b298-abd1fd9b5fc7"};